function ToolIcon({ color, strokeWidth }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.00016 4.00016L7.00016 7.00016M4.00016 4.00016H2.00016L1.3335 2.00016L2.00016 1.3335L4.00016 2.00016V4.00016ZM12.8395 1.8275L11.0877 3.57925C10.8237 3.84326 10.6917 3.97527 10.6423 4.12748C10.5988 4.26138 10.5988 4.40561 10.6423 4.53951C10.6917 4.69173 10.8237 4.82373 11.0877 5.08774L11.2459 5.24592C11.5099 5.50993 11.6419 5.64193 11.7942 5.69139C11.928 5.7349 12.0723 5.7349 12.2062 5.69139C12.3584 5.64193 12.4904 5.50993 12.7544 5.24592L14.393 3.60731C14.5695 4.03675 14.6668 4.50709 14.6668 5.00016C14.6668 7.02521 13.0252 8.66683 11.0002 8.66683C10.756 8.66683 10.5175 8.64297 10.2867 8.59745C9.96257 8.53353 9.80052 8.50158 9.70229 8.51136C9.59785 8.52177 9.54638 8.53743 9.45384 8.58695C9.36679 8.63353 9.27948 8.72085 9.10485 8.89548L4.3335 13.6668C3.78121 14.2191 2.88578 14.2191 2.3335 13.6668C1.78121 13.1145 1.78121 12.2191 2.3335 11.6668L7.10485 6.89548C7.27948 6.72085 7.36679 6.63353 7.41337 6.54649C7.4629 6.45395 7.47856 6.40248 7.48896 6.29804C7.49875 6.1998 7.46679 6.03775 7.40287 5.71366C7.35736 5.48287 7.3335 5.2443 7.3335 5.00016C7.3335 2.97512 8.97512 1.3335 11.0002 1.3335C11.6705 1.3335 12.2988 1.51338 12.8395 1.8275ZM8.0002 10.0001L11.6668 13.6668C12.2191 14.219 13.1145 14.219 13.6668 13.6668C14.2191 13.1145 14.2191 12.219 13.6668 11.6668L10.6504 8.65036C10.4368 8.63015 10.2286 8.59163 10.0274 8.53638C9.76798 8.46519 9.48346 8.51687 9.29327 8.70705L8.0002 10.0001Z"
        stroke={color || "#495B84"}
        strokeWidth={strokeWidth || "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ToolIcon;
