import React, { useEffect, useState } from "react";
import ToolIcon from "../assets/tool";
import QuestionIcon from "../assets/question";
import { useUIText } from "../hooks";
const SelectCard = ({
  icon,
  title,
  underTitle,
  borderColor,
  cursor,
  isSelected,
  onClick
}) => {
  const [cardHovered, setCardHovered] = useState(false);
  return (
    <div
      className="select-card select-card--desktop"
      onClick={onClick}
      style={{
        borderColor: cardHovered || isSelected ? borderColor : "#CEDCE9",
        cursor: cursor,
        boxShadow:
          cardHovered || isSelected
            ? `0px 0px 0px 1px ${borderColor} inset`
            : "none",
        opacity: cardHovered ? 0.6 : 1,
      }}
      onMouseEnter={() => setCardHovered(true)}
      onMouseLeave={() => setCardHovered(false)}
    >
      <div className="select-card__top">
        <span>{icon}</span>
        {title}
      </div>
      <div className="select-card__bottom">{underTitle}</div>
    </div>
  );
};

const SelectCardMobile = ({
  icon,
  title,
  borderColor,
  cursor,
  isSelected,
  onClick,
  defaultIconColor = "#556EA0", // Default color if not provided
}) => {
  const [cardHovered, setCardHovered] = useState(false);
  const [iconColor, setIconColor] = useState(defaultIconColor);

  useEffect(() => {
    setIconColor(isSelected || cardHovered ? "#FFFFFF" : defaultIconColor);
  }, [isSelected, cardHovered, defaultIconColor]);

  return (
    <div
      className="select-card select-card--mobile"
      onClick={onClick}
      style={{
        cursor: cursor,
        opacity: cardHovered ? 0.6 : 1,
      }}
      onMouseEnter={() => setCardHovered(true)}
      onMouseLeave={() => setCardHovered(false)}
    >
      <div
        className="select-card__top"
        style={{
          color:
            cardHovered || isSelected ? borderColor : "rgba(0, 0, 0, 0.87)",
        }}
      >
        <span
          style={{
            backgroundColor:
              cardHovered || isSelected ? borderColor : "#ECF6FF",
          }}
        >
          {React.cloneElement(icon, { color: iconColor })}
        </span>
        {title}
      </div>
    </div>
  );
};

const SelectOnWelcome = ({
  onClick,
  borderColor,
  cursor,
  welcomeOptionSelected,
  setWelcomeOptionSelected,
}) => {
  const uiText = useUIText();
  return (
		<div className="select-cards">
			<SelectCard
				key={"second-card"}
				icon={<QuestionIcon color="#556EA0" />}
				title={uiText.form.openQuestionsTitle}
				underTitle={uiText.form.openQuestionsDescription}
				borderColor={borderColor}
				cursor={cursor}
				isSelected={welcomeOptionSelected === 2}
				onClick={() => onClick(2)}
			/>
			<SelectCard
				key={"first-card"}
				icon={<ToolIcon color="#556EA0" strokeWidth="1.5" />}
				title={uiText.form.deviceSupportTitle}
				underTitle={uiText.form.deviceSupportDescription}
				borderColor={borderColor}
				cursor={cursor}
				isSelected={welcomeOptionSelected === 1}
				onClick={() => onClick(1)}
			/>
			<SelectCardMobile
				key={"second-card-mobile"}
				icon={<QuestionIcon />}
				title={uiText.form.openQuestionsTitle}
				borderColor={borderColor}
				cursor={cursor}
				isSelected={welcomeOptionSelected === 2}
				onClick={() => onClick(2)}
				defaultIconColor="#556EA0"
			/>
			<SelectCardMobile
				key={"first-card-mobile"}
				icon={<ToolIcon strokeWidth="1.5" />}
				title={uiText.form.deviceSupportTitle}
				borderColor={borderColor}
				cursor={cursor}
				isSelected={welcomeOptionSelected === 1}
				onClick={() => onClick(1)}
				defaultIconColor="#556EA0"
			/>
		</div>
	);
};

export default SelectOnWelcome;
