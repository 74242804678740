const StepsComponent = ({ inputText, language }) => {
	// Function to format HTML content
	function formatContent(html) {
		// Preserve <a> tags by temporarily replacing them with placeholders
		const aTagRegex = /<a href="([^"]+)">([^<]*)<\/a>/gi;
		const aTagPlaceholders = [];
		html = html.replace(aTagRegex, (match, href, text) => {
			const placeholder = `%%aTag%%${aTagPlaceholders.length}%%`;
			aTagPlaceholders.push({ href, text });
			return placeholder;
		});

		// Convert <b>...</b> tags to bold text content
		const boldTagRegex = /<b>(.*?)<\/b>/gi;
		html = html.replace(boldTagRegex, "<b>$1</b>");

		// Convert <em>...</em> tags to italic text content
		const emTagRegex = /<em>(.*?)<\/em>/gi;
		html = html.replace(emTagRegex, "<i>$1</i>");

		// Replace "arr;" with the arrow symbol, but only when it's not part of another word
		html = html.replace(/\barr;/g, "→"); // \b ensures "arr;" is matched as a word boundary

		// Keep the existing logic for handling bold and italic text outside of <b> tags
		const boldItalicRegex = /(\*\*|__)(.*?)\1/g;
		const italicRegex = /(\*|_)(.*?)\1/g;
		html = html.replace(boldItalicRegex, "<b>$2</b>");
		html = html.replace(italicRegex, "<i>$2</i>");

		// Handling warnings
		const warningRegex = /<p>(Warning:|Warnung:|Achtung:)(.*?)<\/p>/g;
		html = html.replace(warningRegex, (match, p1, p2) => {
			if (language !== "German" && p1.startsWith("Warnung: Es")) {
				return "";
			}
			return `<p><span role="img" aria-label="warning" style="margin-right: 5px;">⚠️</span> ${p1}${p2}</p>`;
		});

		// Handling steps
		const stepRegex =
			/<p>(Step|Schritt|Paso|Krok|Étape) (\d+)(?:\..*?)? *:?\s*([^0-9].*)<\/p>/gi;
		html = html.replace(stepRegex, (match, p1, p2, p3) => {
			return `
                <div class="step">
                    <div style="
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background-color: #E3E9F2;
                        color: #000;
                        text-align: center;
                        line-height: 26px;
                        margin-right: 5px;
                        font-size: 12px;
                        font-weight: 700;
                    ">${p2}</div>
                    <span>${p3}</span>
                </div>
            `;
		});

		// Restore <a> tags from placeholders with target="_blank" and rel="noopener noreferrer"
		html = html.replace(/%%aTag%%(\d+)%%/g, (match, index) => {
			const { href, text } = aTagPlaceholders[Number(index)];
			return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
		});

		return html;
	}
	// Check if inputText contains HTML tags
	const containsHTML = /<\/?[a-z][\s\S]*>/i.test(inputText);

	// Format the entire input text content
	const formattedContent = formatContent(inputText);

	return (
		<div>
			{containsHTML ? (
				<div dangerouslySetInnerHTML={{ __html: formattedContent }} />
			) : (
				<div>{formattedContent}</div>
			)}
		</div>
	);
};

export default StepsComponent;
