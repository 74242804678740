import React from 'react'

export default function NotFound() {
  return (
    <div className='error-page'>
      <h1>404</h1>

      <p>
        Page not found. Go back to <a href="/">home</a>
      </p>
    </div>
  )
}
