import { Tooltip } from "react-tooltip";
import { ReactComponent as CalibrationIcon } from "../assets/calibration.svg";
import { useUIText } from "../hooks";
const Banner = ({ feedbackIsAdded }) => {
  const uiText = useUIText();
  const adjustTooltipPosition = () => {
    const bannerLeft = document.querySelector(".banner-left");
    const tooltip = document.getElementById("bannerTooltip");
    if (bannerLeft && tooltip) {
      const bannerLeftRect = bannerLeft.getBoundingClientRect();
      const tooltipRect = tooltip.getBoundingClientRect();
      const margin = 16; // 1rem in pixels

      let tooltipLeft =
        bannerLeftRect.left + bannerLeftRect.width / 2 - tooltipRect.width / 2;

      // Ensure tooltip stays within the viewport with at least 1rem margin
      const viewportWidth = window.innerWidth;
      if (tooltipLeft < margin) {
        tooltipLeft = margin;
      } else if (tooltipLeft + tooltipRect.width > viewportWidth - margin) {
        tooltipLeft = viewportWidth - margin - tooltipRect.width;
      }

      tooltip.style.left = `${tooltipLeft}px`;
    }
  };
  return (
    <div id="gradient" data-tooltip-id="bannerTooltip">
      <div className="banner">
        <div className="banner-left">
          <CalibrationIcon />
          <span>{uiText?.banner?.title}</span>
        </div>
        <div className="banner-right">
          {feedbackIsAdded
            ? uiText?.banner?.feedbackAdded
            : uiText?.banner?.aiLearning}
        </div>
      </div>
      <Tooltip
        id="bannerTooltip"
        className="banner-tooltip"
        place="bottom"
        effect="solid"
        positionStrategy="fixed"
        afterShow={adjustTooltipPosition}
      >
        <div>{uiText?.banner?.tooltip}</div>
      </Tooltip>
    </div>
  );
};

export default Banner;
