import React, { useState, useRef } from "react";
import { ReactComponent as RiArrowDropDownLine } from "../assets/down-arrow.svg";
import { ReactComponent as DocIcon } from "../assets/file-text.svg";
import {useUIText} from "../hooks";

// Helper function to replace <a> tags with their text content and remove <img> tags
const replaceLinksAndRemoveImages = (htmlString) => {
	// Create a new DOM parser
	var parser = new DOMParser();

	// Parse the HTML string
	var doc = parser.parseFromString(htmlString, "text/html");

	// Get all the <a> tags and convert to array
	var links = Array.from(doc.getElementsByTagName("a"));

	// Replace each <a> tag with its text content
	for (var i = 0; i < links.length; i++) {
		links[i].replaceWith(links[i].textContent);
	}

	// Get all the <img> tags and convert to array
	var images = Array.from(doc.getElementsByTagName("img"));

	// Remove each <img> tag
	// eslint-disable-next-line no-redeclare
	for (var i = 0; i < images.length; i++) {
		images[i].remove();
	}

	// Get the updated HTML string
	htmlString = doc.body.innerHTML;

	// Replace consecutive <br> tags with a single <br> tag
	htmlString = htmlString.replace(/(<br>\s*){2,}/g, "<br>");

	return htmlString;
};

const AccordionItem = ({ article, isOpen, onClick }) => {
	const uiText = useUIText();
	const contentHeight = useRef();
	return (
		<div className="wrapper article">
			<button
				className={`question-container feedback-file ${isOpen ? "opened" : ""}`}
				onClick={onClick}
			>
				<div>
					<DocIcon className="article-icon" />
				</div>
				<span className="question-content" style={{ fontSize: "14px" }}>
					{article.Title}
				</span>
				<RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} />
			</button>

			<div
				ref={contentHeight}
				className="answer-container"
				style={
					isOpen
						? { height: contentHeight.current.scrollHeight }
						: { height: "0px" }
				}
			>
				<div className="answer-content">
					<div className="article-text">
						<div className="article-text__title">{uiText?.result?.tabs?.instructions?.accordion?.summary}:</div>
						{article.Summary && <p>{article.Summary}</p>}
					</div>
					<div className="article-text">
						<div className="article-text__title">{uiText?.result?.tabs?.instructions?.accordion?.description}:</div>
						{article.Description && (
							<div
								dangerouslySetInnerHTML={{
									__html: replaceLinksAndRemoveImages(article.Description),
								}}
								className="article-text__content"
							></div>
						)}
					</div>
					<div className="article-text">
						<div className="article-text__title">{uiText?.result?.tabs?.instructions?.accordion?.supportedDevices}:</div>
						{article["Supported Devices"] && (
							<div className="article-categories">
								{article["Supported Devices"].map((device, index) => (
									<span key={index} className="article-category">
										{device}
									</span>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const Accordion = ({ data, single }) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const handleItemClick = (index) => {
		setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	return (
		<div
			className={single ? "accordion-container single" : "accordion-container"}
		>
			{data.map((article, index) => (
				<AccordionItem
					key={index}
					article={article}
					isOpen={activeIndex === index}
					onClick={() => handleItemClick(index)}
				/>
			))}
		</div>
	);
};

export default Accordion;