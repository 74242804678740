import { useState, useEffect } from "react";
function useIsEmbedded() {
  const [isEmbedded, setIsEmbedded] = useState(false);

  useEffect(() => {
    setIsEmbedded(window.self !== window.top);
  }, []);

  return isEmbedded;
}
export default useIsEmbedded;
