import React, { useState, useRef, useEffect } from "react";

const AutoResizeTextarea = ({
	placeholder,
	color,
	value,
	onChange,
	idForArea = "problem",
	isDisabled = false,
	isWebsite = false,
}) => {
  const [outlineColor, setOutlineColor] = useState("rgb(144 160 183)"); // Default outline color
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef(null);

  const handleFocus = () => {
    // Set the outline color to the passed color prop when focused
    setOutlineColor(color);
    setIsFocused(true);
  };

  const handleBlur = () => {
    // Reset the outline color when the textarea loses focus
    setOutlineColor("rgb(144 160 183)");
    setIsFocused(false);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Ensure height is auto before measuring scrollHeight
      textarea.style.height = "auto";
      if (textarea.scrollHeight > 60) {
        const newHeight = Math.max(52, textarea.scrollHeight);
        textarea.style.height = `${newHeight}px`;
      } else {
        textarea.style.height = "52px";
      }
    }
  }, [value]);

	return (
		<textarea
			ref={textareaRef}
			type="text"
			id={idForArea}
			name="problem"
			rows={1}
			className="customer-textarea"
			style={{
				borderColor: color,
				maxHeight: "250px",
				minHeight: "52px",
				paddingBottom: "10px",
				background: "white",
				outline: isFocused ? `2px solid ${outlineColor}` : "none", // Apply dynamic outline color
				boxShadow: isWebsite
					? "0px 1px 5px 0px rgba(69, 188, 239, 0.40)"
					: "none",
				borderWidth: isWebsite ? "1.5px" : "1px",
			}}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			disabled={isDisabled}
			onFocus={handleFocus}
			onBlur={handleBlur}
		/>
	);
};

export default AutoResizeTextarea;
