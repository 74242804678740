function MicrophoneIcon({ color }) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.6666 8C10.6666 9.46667 9.46663 10.6667 7.99996 10.6667C6.53329 10.6667 5.33329 9.46667 5.33329 8V2.66667C5.33329 1.2 6.53329 0 7.99996 0C9.46663 0 10.6666 1.2 10.6666 2.66667V8ZM7.99996 1.33333C7.26663 1.33333 6.66663 1.93333 6.66663 2.66667V8C6.66663 8.73333 7.26663 9.33333 7.99996 9.33333C8.73329 9.33333 9.33329 8.73333 9.33329 8V2.66667C9.33329 1.93333 8.73329 1.33333 7.99996 1.33333ZM12 6.66667C12 6.26667 12.2666 6 12.6666 6C13.0666 6 13.3333 6.26667 13.3333 6.66667V8C13.3333 10.6667 11.2666 12.9333 8.66663 13.2667V14.6667H10.6666C11.0666 14.6667 11.3333 14.9333 11.3333 15.3333C11.3333 15.7333 11.0666 16 10.6666 16H5.33329C4.93329 16 4.66663 15.7333 4.66663 15.3333C4.66663 14.9333 4.93329 14.6667 5.33329 14.6667H7.33329V13.2667C4.73329 12.9333 2.66663 10.7333 2.66663 8V6.66667C2.66663 6.26667 2.93329 6 3.33329 6C3.73329 6 3.99996 6.26667 3.99996 6.66667V8C3.99996 10.2 5.79996 12 7.99996 12C10.2 12 12 10.2 12 8V6.66667Z"
				fill={color || "#ffffff"}
			/>
		</svg>
	);
}

export default MicrophoneIcon;