import { useContext, useMemo } from "react";
import { AppContext } from "../context";
import translations from "../translation/index";

function useUIText() {
  const { instanceInfo, originalInstanceInfo } = useContext(AppContext);
  const mainLanguage = instanceInfo.mainLanguage;

  const uiText = useMemo(() => {
    const matchingMainLanguage =
      originalInstanceInfo.mainLanguage === mainLanguage;
    if (Object.keys(translations).includes(mainLanguage)) {
      if (matchingMainLanguage) {
        const { greetings } = originalInstanceInfo.texts ?? {};
        return {
          ...translations[mainLanguage],
          form: {
            ...translations[mainLanguage].form,
            greetings:
              greetings?.length > 0
                ? greetings
                : translations[mainLanguage].form.greetings,
          },
        };
      }
      return translations[mainLanguage];
    }
    return translations["English"];
  }, [
    mainLanguage,
    originalInstanceInfo.texts,
    originalInstanceInfo.mainLanguage,
  ]);

  return uiText;
}

export default useUIText;
