const CheckedIcon = ({color}) => {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M7.32923 13.2292L3.85423 9.75417L2.6709 10.9292L7.32923 15.5875L17.3292 5.58751L16.1542 4.41251L7.32923 13.2292Z"
				fill={color || "white"}
			/>
		</svg>
	);
}
 
export default CheckedIcon;