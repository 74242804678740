import React from 'react'

export default function ErrorPage({
  statusCode,
  message,
}) {
  return (
    <div className='error-page'>
      <h1>{statusCode}</h1>

      <p>
        {message}
      </p>
    </div>
  )
}
