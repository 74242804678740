import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as SlidersIcon } from "../assets/sliders.svg";
import { useUIText } from "../hooks";

const Switch = ({ view, handleViewChange, mainColor }) => {
  const uiText = useUIText();
  const [isSwitchVisible, setIsSwitchVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1111);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1111);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOptionClick = () => {
    setIsSwitchVisible(true);
  };

  const handleCloseClick = () => {
    setIsSwitchVisible(false);
  };

  return (
    <div style={{ position: "relative", marginLeft: "auto" }}>
      {isMobileView ? (
        isSwitchVisible ? (
          <div className="switch-container visible">
            <p
              className={
                view === "Installer"
                  ? "installer-text checked"
                  : "installer-text"
              }
              style={{ color: view === "Installer" ? mainColor : "#90A0B7" }}
            >
              {uiText?.instanceType?.installer}
            </p>
            <label className="switch">
              <input
                type="checkbox"
                id="modeToggle"
                onChange={handleViewChange}
                checked={view === "Customer"}
              />
              <span
                className="slider"
                style={{ backgroundColor: mainColor }}
              ></span>
            </label>
            <p
              className={
                view === "Installer" ? "customer-text" : "customer-text checked"
              }
              style={{ color: view === "Installer" ? "#90A0B7" : mainColor }}
            >
              {uiText?.instanceType?.customer}
            </p>
            <button className="close-button" onClick={handleCloseClick}>
              <CloseIcon />
            </button>
          </div>
        ) : (
          <button className="option-button" onClick={handleOptionClick}>
            <SlidersIcon />
            {/* <div className="option-dots">
							<span></span>
							<span></span>
							<span></span>
						</div> */}
          </button>
        )
      ) : (
        <div className="switch-container">
          <p
            className={
              view === "Installer" ? "installer-text checked" : "installer-text"
            }
            style={{ color: view === "Installer" ? mainColor : "#90A0B7" }}
          >
            {uiText?.instanceType?.installer}
          </p>
          <label className="switch">
            <input
              type="checkbox"
              id="modeToggle"
              onChange={handleViewChange}
              checked={view === "Customer"}
            />
            <span
              className="slider"
              style={{ backgroundColor: mainColor }}
            ></span>
          </label>
          <p
            className={
              view === "Installer" ? "customer-text" : "customer-text checked"
            }
            style={{ color: view === "Installer" ? "#90A0B7" : mainColor }}
          >
            {uiText?.instanceType?.customer}
          </p>
        </div>
      )}
    </div>
  );
};

export default Switch;
