import Navbar from "./Navbar";
import Banner from "./Banner";
import FormContainer from "./FormContainer";
import { useState, useEffect } from "react";
import FullAccessWelcome from "./full-access/FullAccessWelcome";

const Installer = ({
  logo,
  typeOfDemo,
  handleViewChange,
  feedbackIsAdded,
  variables,
  setFeedbackIsAdded,
}) => {
  const [resetForm, setResetForm] = useState(false);
  const [showFullAccess, setShowFullAccess] = useState(false);

  useEffect(() => {
    if (variables.limitReached) {
      setShowFullAccess(true);
    }
  }, [variables.limitReached]);

  return (
    <div className="installer-wrapper">
      <div className={`app ${showFullAccess ? "blurred" : ""}`}>
        {variables?.hideHeader ? null : (
          <Navbar
            logo={logo}
            typeOfDemo={typeOfDemo}
            handleViewChange={handleViewChange}
            onLogoClick={() => setResetForm(true)}
            mainColor={variables.mainColor}
            hasBorder={variables.active}
          />
        )}
        {variables.active ? null : <Banner feedbackIsAdded={feedbackIsAdded} />}
        <div className="main-container">
          <FormContainer
            resetForm={resetForm}
            setResetForm={setResetForm}
            variables={variables}
            setFeedbackIsAdded={setFeedbackIsAdded}
            setShowFullAccess={setShowFullAccess}
          />
        </div>
      </div>
      {showFullAccess && (
        <div className="full-access-overlay">
          <FullAccessWelcome />
        </div>
      )}
    </div>
  );
};

export default Installer;
