function ArrowIcon({ color }) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_131_2024)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 2.00001C4.6863 2.00001 2.00001 4.6863 2.00001 8C2.00001 11.3137 4.6863 14 8 14C11.3137 14 14 11.3137 14 8C14 4.6863 11.3137 2.00001 8 2.00001ZM0.666672 8C0.666672 3.94992 3.94992 0.666672 8 0.666672C12.0501 0.666672 15.3333 3.94992 15.3333 8C15.3333 12.0501 12.0501 15.3333 8 15.3333C3.94992 15.3333 0.666672 12.0501 0.666672 8Z"
					fill={color || "#5A55AB"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.52859 4.86193C7.78894 4.60158 8.21105 4.60158 8.4714 4.86193L11.1381 7.5286C11.3984 7.78895 11.3984 8.21106 11.1381 8.47141L8.4714 11.1381C8.21105 11.3984 7.78894 11.3984 7.52859 11.1381C7.26824 10.8777 7.26824 10.4556 7.52859 10.1953L9.72385 8L7.52859 5.80474C7.26824 5.54439 7.26824 5.12228 7.52859 4.86193Z"
					fill={color || "#5A55AB"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.66667 7.99999C4.66667 7.63181 4.96515 7.33333 5.33334 7.33333H10.6667C11.0349 7.33333 11.3333 7.63181 11.3333 7.99999C11.3333 8.36818 11.0349 8.66666 10.6667 8.66666H5.33334C4.96515 8.66666 4.66667 8.36818 4.66667 7.99999Z"
					fill={color || "#5A55AB"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_131_2024">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default ArrowIcon;
