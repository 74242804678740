import LoadingAnimation from "./LoadingAnimation";

const ProgressBar = ({ progress, status, color, showBar=true }) => {
	return showBar ? (
		<div>
			<div className="progress-text">{progress}%</div>
			<div className="progress-bar__background">
				<div
					className="progress-bar"
					style={{ width: `${progress}%`, backgroundColor: color }}
				></div>
			</div>
			<div className="status-text">{status}</div>
		</div>
	) : (
		<div>
			<LoadingAnimation color={color} />
			<div className="status-text">{status}</div>
		</div>
	);
};

export default ProgressBar;
