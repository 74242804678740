import React, { useState } from "react";
// import FaqsAccordion from "./FaqsAccordion";
import { renderIcon } from "../utils";
import { useUIText } from "../hooks";

const FaqCard = ({ faq, borderColor, handleFaqClick }) => {
  const [cardHovered, setCardHovered] = useState(false);
  return (
    <div
      className="faq-card"
      style={{
        borderColor: borderColor,
        boxShadow: cardHovered
          ? `0px 0px 0px 1px ${borderColor} inset`
          : "none",
      }}
      onClick={() => handleFaqClick(faq)}
      onMouseEnter={() => setCardHovered(true)}
      onMouseLeave={() => setCardHovered(false)}
    >
      <div className="faq-card__icon">
        {renderIcon(faq.icon, cardHovered ? borderColor : null)}
      </div>
      <div
        className="faq-card__question"
        style={{ color: cardHovered ? borderColor : "#152540" }}
      >
        {faq.question}
      </div>
      <div
        className="faq-card__answer"
        dangerouslySetInnerHTML={{ __html: faq.answer }}
      />
    </div>
  );
};
const Faqs = ({
  faqs,
  borderColor,
  displayedFaq,
  handleFaqClick,
  tabVersion = false,
}) => {
  const uiText = useUIText();
  return (
    <div className="faq-container">
      {displayedFaq === null ? (
        <>
          <div
            className="faq-main__title"
            style={{ textAlign: tabVersion ? "left" : "center" }}
          >
            {uiText.faq.title}
          </div>
          <div className="faq-grid">
            {faqs
              .filter((faq) => faq.selected)
              .slice(0, 4)
              .map((faq, index) => (
                <FaqCard
                  key={index}
                  faq={faq}
                  borderColor={borderColor}
                  handleFaqClick={handleFaqClick}
                />
              ))}
          </div>
        </>
      ) : (
        <>
          <div className="faq-question">{displayedFaq.question}</div>
          <div
            className="feedback-instructions faq-answer"
            dangerouslySetInnerHTML={{ __html: displayedFaq.answer }}
          />
          {/* <FaqsAccordion
						faqs={faqs}
						borderColor={borderColor}
						handleFaqClick={handleFaqClick}
					/> */}
        </>
      )}
    </div>
  );
};

export default Faqs;
