import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { handleSendResult, handleFeedbackSubmit } from "../zaps/zaps";
import {
  getBrightness,
  audioBlobToBase64,
  formatDeviceName,
  randomId,
} from "../utils";
import StepsComponent from "./StepsComponent";
import NoResults from "./NoResults";
import ProgressBar from "./ProgressBar";
import VoiceAnimation from "./VoiceAnimation";
import Warning from "./Warning";
import ArrowRightIcon from "../assets/arrowRight";
import ThumbUpIconCustomer from "../assets/thumbUpCustomer";
import ThumbDownIconCustomer from "../assets/thumbDownCustomer";
import MicrophoneIcon from "../assets/mic";
import { useUIText } from "../hooks";
import AutoResizeTextarea from "./TextArea";
import Logo from "../assets/logo-ver2.svg";
import { sendQuestionData, sendUserFeedback } from "../utils";
import SAicon from "../assets/sa-icon";
import ConversationFeedback from "./ConversationFeedback";
import { useIsEmbedded } from "../hooks";
import WebsiteSteps from "./WebsiteSteps";
import { AppContext } from "../context";
import OtherQuestions from "./OtherQuestions";

const languageCodeMap = {
  English: "en-US",
  German: "de-DE",
  French: "fr-FR",
  Polish: "pl-PL",
  Spanish: "es-ES",
};

const brandColor = "#8745ef";

function useQueryParams() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const website = queryParams.get("website");
  const logo = queryParams.get("logo");

  // Parse the questions parameter
  const otherQuestionsString = queryParams.get("questions");
  if (otherQuestionsString) {
    try {
      // Remove outer square brackets and split by commas
      // const innerQuestionsString = otherQuestionsString
      // 	.replace(/^\[|\]$/g, "")
      // 	.split(",");

      // Map each inner question, removing quotes and trimming whitespace
      // const parsedQuestions = innerQuestionsString.map((question) =>
      // 	question.trim().replace(/^'|'$/g, "")
      // );

      const parsedQuestions = JSON.parse(otherQuestionsString);

      return {
        website,
        logo,
        questions: parsedQuestions,
      };
    } catch (error) {
      console.error("Error parsing questions:", error);
      return {
        website,
        logo,
        questions: [], // Return an empty array if parsing fails
      };
    }
  } else {
    return {
      website,
      logo,
      questions: [],
    };
  }
}

const DemoWebsite = () => {
  const { website, logo, questions } = useQueryParams();

  const url = new URL(website);
  const hostname = url.hostname;
  const uiText = useUIText();
  const environment = process.env.REACT_APP_ENV;

  const { instanceInfo } = useContext(AppContext);
  const mainLanguage = instanceInfo.mainLanguage;
  const parsedUrl = new URL(website);
  const rootUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}`;

  const [userInput, setUserInput] = useState({
    installer_fallback: true,
    gpt: true,
    company: rootUrl,
    demo_name: website,
    device: hostname,
    problem: "",
    language: mainLanguage,
    instance_type: "installer",
  });
  const [conversationFeedbackAdded, setConversationFeedbackAdded] =
    useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(uiText?.status?.processing);
  const [delay, setDelay] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);

  const [showBackButton, setShowBackButton] = useState(false);

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  const [backBtnHovered, setBackBtnHovered] = useState(false);
  const [displayedFaq, setDisplayedFaq] = useState(null);
  const [conversationId, setConversationId] = useState(null); //for admin backend
  const [sessionId, setSessionId] = useState(null); //for numlab api
  const [conversation, setConversation] = useState([]);
  const [firstQuestion, setFirstQuestion] = useState("");
  const [resetFeedback, setResetFeedback] = useState(false);
  const [feedbackDisabled, setFeedbackDisabled] = useState(false);
  const [instanceChecked, setInstanceChecked] = useState(false);

  const resetForm = () => {
    setShowData(false);
    setShowQuestion(false);
    setShowProgressBar(false);
    setError(null);
    setFileError(null);
    setData({});
    setConversationFeedbackAdded(false);
    setShowBackButton(false);
    setBackBtnHovered(false);
    setDisplayedFaq(null);
    setUserInput((cur) => ({
      ...cur,
      installer_fallback: true,
      gpt: true,
      company: website,
      demo_name: website,
      device: hostname,
      problem: "",
    }));
    setSessionId(null);
    setConversation([]);
    setConversationId(null);
    setResetFeedback(false);
    setFeedbackDisabled(false);
  };

  const fetchData = async () => {
    conversation.length < 1 && setFirstQuestion(userInput.problem);
    setShowQuestion(true);
    setTimeout(() => {
      setLoading(true);
    }, 10);
    let url = null;
    const environment = process.env.REACT_APP_ENV;
    if (environment === "production") {
      url = "https://new-ai.service-assistant.ai/get_instructions";
    } else {
      url = "https://new-ai.service-assistant-staging.com/get_instructions";
    }
    try {
      let session_id = sessionId;
      if (!sessionId) {
        session_id = randomId();
        setSessionId(session_id);
      }
      if (url) {
        const res = await axios.post(
          url,
          {
            ...userInput,
            session_id: session_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data) {
          const newConversationEntry = {
            question: userInput.problem,
            data: res.data,
            recordId: null,
            showThumbs: true,
            device: userInput.device,
            conversationError: false,
          };
          setData(res.data);
          setConversation((prevConversation) => [
            ...prevConversation,
            newConversationEntry,
          ]);
          setConversationFeedbackAdded(false);
          sendQuestionData(
            userInput,
            res.data?.model_response || "",
            res.data?.exact_file || "",
            res.data?.page || [],
            session_id,
            conversationId,
            false,
            res.data?.empty_answer
          ).then((response) => {
            if (response && response.recordId) {
              setConversation((prevConversation) =>
                prevConversation.map((entry, index) =>
                  index === prevConversation.length - 1
                    ? { ...entry, recordId: response.recordId }
                    : entry
                )
              );
            }
            if (response && response.conversationId) {
              setConversationId(response.conversationId);
            }
          });
        } else {
          setData([]);
        }
      }
    } catch (err) {
      const newConversationEntry = {
        question: userInput.problem,
        data: null,
        recordId: null,
        showThumbs: false,
        device: userInput.device,
        conversationError: true,
      };
      setConversation((prevConversation) => [
        ...prevConversation,
        newConversationEntry,
      ]);
      console.log(err.message); // Log only the error message
      setError(err.message); // Set only the error message
      if (environment === "production") {
        handleSendResult(false, data, userInput, website, true);
      }
      setShowProgressBar(false);
      setShowQuestion(false);
      setProgress(0);
      setStatus("");
      sendQuestionData(
        userInput,
        err.message || "error",
        "",
        [],
        sessionId,
        conversationId,
        true
      ).then((response) => {
        if (response && response.recordId) {
          setConversation((prevConversation) =>
            prevConversation.map((entry, index) =>
              index === prevConversation.length - 1
                ? { ...entry, recordId: response.recordId }
                : entry
            )
          );
        }
        if (response && response.conversationId) {
          setConversationId(response.conversationId);
        }
      });
    }
    setConversationFeedbackAdded(false);
    setLoading(false);
    setFileError(null);
    setProgress(100); // Set progress to 100
    setStatus(uiText?.status?.complete); // Set status to "Complete"
    setTimeout(() => {
      setProgress(0);
      setStatus("");
      setShowProgressBar(false);
      setShowQuestion(false);
      setShowData(true);
    }, 300);
    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      problem: "",
    }));
  };

  useEffect(() => {
    let interval = null;
    if (loading && !delay) {
      setShowProgressBar(true);
      if (progress === 0) {
        setStatus(uiText?.status?.processing);
      }
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          let newProgress = prevProgress + 1;
          if (newProgress > 100) {
            newProgress = 100; // Ensure progress does not exceed 100
          }
          if (
            newProgress === 5 ||
            newProgress === 25 ||
            newProgress === 50 ||
            newProgress === 75
          ) {
            setStatus(
              newProgress === 5
                ? uiText?.status?.processing
                : newProgress === 25 || newProgress === 50
                ? uiText?.status?.searching
                : uiText?.status?.generating
            );
            clearInterval(interval);
            setDelay(true);
            setTimeout(() => setDelay(false), 500); // Add a delay of half a second
          }
          return newProgress;
        });
      }, 200); // Adjust the time here to control the speed of the progress bar
    } else if (!loading && progress > 1) {
      setProgress(100);
      setStatus(uiText?.status?.complete);
      setTimeout(() => {
        setProgress(0);
        setStatus("");
        setShowProgressBar(false);
        setTimeout(() => setShowData(true), 300); // Delay showing data until after ProgressBar has disappeared
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading, delay, progress, uiText]);

  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (!userInput) return;
    setData({});
    fetchData();
    // setUserInput({ device: "", problem: "", language: "English" });
    setError(null);
    setFileError(null);
    setShowBackButton(false);
    setTimeout(() => {
      const questions = document.querySelectorAll(".customer-question");
      const lastQuestion = questions[questions.length - 1];
      if (lastQuestion) {
        lastQuestion.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  };

  useEffect(() => {
    if (conversationFeedbackAdded) {
      setConversationFeedbackAdded(true);
      setTimeout(() => {
        setConversationFeedbackAdded(false);
      }, 3500);
    } else {
      setConversationFeedbackAdded(false);
    }
  }, [conversationFeedbackAdded, setConversationFeedbackAdded]);

  if (!process.env.REACT_APP_GOOGLE_API_KEY) {
    throw new Error("REACT_APP_GOOGLE_API_KEY not found in the environment");
  }

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);
      recorder.start();
      console.log("Recording started");
      setUserInput({
        ...userInput,
        problem: "",
      });

      // Event listener to handle data availability
      recorder.addEventListener("dataavailable", async (event) => {
        console.log("Data available event triggered");
        const audioBlob = event.data;

        const base64Audio = await audioBlobToBase64(audioBlob);
        //console.log('Base64 audio:', base64Audio);
        setIsSpinnerVisible(true);
        try {
          const startTime = performance.now();

          const response = await axios.post(
            `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
            {
              config: {
                encoding: "WEBM_OPUS",
                sampleRateHertz: 48000,
                languageCode: languageCodeMap[userInput.language] || "en-US",
                enableAutomaticPunctuation: true,
                useEnhanced: true,
                model: "latest_long",
              },
              audio: {
                content: base64Audio,
              },
            }
          );

          const endTime = performance.now();
          const elapsedTime = endTime - startTime;

          //console.log('API response:', response);
          console.log("Time taken (ms):", elapsedTime);

          if (response.data.results && response.data.results.length > 0) {
            setIsSpinnerVisible(false);
            setTranscription(
              response.data.results[0].alternatives[0].transcript
            );
            setUserInput({
              ...userInput,
              problem: response.data.results[0].alternatives[0].transcript,
            });
          } else {
            console.log(
              "No transcription results in the API response:",
              response.data
            );
            setTranscription(uiText?.form?.noTranscription);
          }
          setIsSpinnerVisible(false);
        } catch (error) {
          console.error(
            "Error with Google Speech-to-Text API:",
            error.response.data
          );
        }
      });

      setRecording(true);
      setMediaRecorder(recorder);
    } catch (error) {
      console.error("Error getting user media:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      console.log("Recording stopped");
      setRecording(false);
      // Stop the MediaStream
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const hideForm =
    showProgressBar ||
    error ||
    fileError ||
    (showData && Object.keys(data).length > 0) ||
    loading ||
    showQuestion ||
    displayedFaq !== null ||
    conversation.length > 0;

  useEffect(() => {
    if (
      !loading &&
      ((showData && Object.keys(data).length > 0) || error || fileError)
    ) {
      setTimeout(() => {
        setShowBackButton(true);
      }, 1000);
    }
  }, [showData, data, error, fileError, loading]);

  const latestConversationRef = useRef(null);

  useEffect(() => {
    if (conversation.length > 0) {
      setResetFeedback(true);
    }
    // Scroll to the latest conversation entry
    if (latestConversationRef.current) {
      latestConversationRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
    setTimeout(() => {
      setResetFeedback(false);
    }, 0);
  }, [conversation.length]);

  useEffect(() => {
    if (conversation.length > 0 && !instanceChecked) {
      setInstanceChecked(true);
    }
  }, [conversation.length, instanceChecked]);

  const UserFeedback = ({ recordId, data, question, index, showingThumbs }) => {
    const [feedbackAdded, setFeedbackAdded] = useState(false);
    const [showThumbs, setShowThumbs] = useState(true);
    const [showFeedbackInput, setShowFeedbackInput] = useState(false);
    const [feedbackInput, setFeedbackInput] = useState("");

    useEffect(() => {
      if (feedbackAdded) {
        setTimeout(() => {
          setShowThumbs(false);
          setConversationThumb(index, false);
        }, 3500);
      } else {
        setShowThumbs(true);
      }
    }, [feedbackAdded, index]);

    if (!showThumbs || !showingThumbs) {
      return null;
    }

    return (
      <div
        className={
          showFeedbackInput ? "user-feedback show-input" : "user-feedback"
        }
      >
        {feedbackAdded ? (
          <div className="user-feedback__text success">
            {uiText?.result?.feedback.thankYou}
          </div>
        ) : showFeedbackInput ? (
          <div className="user-feedback__input">
            <textarea
              value={feedbackInput}
              onChange={(e) => setFeedbackInput(e.target.value)}
              placeholder={uiText?.result?.feedback.inputPlaceholder}
              type="text"
              id="feedback-input"
              name="feedback-input"
              className="card"
              style={{
                maxHeight: "250px",
                minHeight: "42px",
                height: "42px",
              }}
            />
            <button
              type="submit"
              className="submit-btn"
              style={{
                backgroundColor: brandColor,
                color: getBrightness(brandColor) > 155 ? "black" : "white",
                border: `1px solid ${brandColor}`,
                marginTop: "0px",
              }}
              onClick={() => {
                if (environment === "production") {
                  handleFeedbackSubmit(
                    "Thumb Down",
                    data,
                    question,
                    setFeedbackAdded,
                    website,
                    feedbackInput,
                    setShowFeedbackInput,
                    true
                  );
                } else {
                  setFeedbackAdded(true);
                  setShowFeedbackInput(false);
                }
                sendUserFeedback(recordId, false, conversationId);
                setConversationThumb(index, false);
              }}
            >
              <div className="thumb-button">
                {uiText?.result?.feedback.submit}
              </div>
            </button>
          </div>
        ) : (
          <>
            <div className="user-feedback__buttons">
              <div
                className="user-feedback__button"
                onClick={() => setShowFeedbackInput(true)}
                style={{ borderColor: "#B3CADD" }}
              >
                <ThumbDownIconCustomer color={"#B3CADD"} />
              </div>
              <div
                className="user-feedback__button"
                onClick={() => {
                  if (environment === "production") {
                    handleFeedbackSubmit(
                      "Thumb Up",
                      data,
                      question,
                      setFeedbackAdded,
                      website,
                      "",
                      null,
                      true
                    );
                  } else {
                    setFeedbackAdded(true);
                  }
                  sendUserFeedback(recordId, true, conversationId);
                  setConversationThumb(index, false);
                }}
                style={{ borderColor: "#B3CADD" }}
              >
                <ThumbUpIconCustomer color={"#B3CADD"} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const focusOnTextarea = () => {
    setTimeout(() => {
      const textarea = document.getElementById("follow-up");
      if (textarea) {
        textarea.focus();
      }
    }, 100);
  };

  const onConversationFeedbackSubmit = (value) => {
    sendUserFeedback(null, value, conversationId);
    setFeedbackDisabled(true);
  };

  const checkIfFeedbackIsNeeded = (item) => {
    return (
      item.data?.model_response.length > 0 && item.data?.empty_answer === false
    );
  };

  const setConversationThumb = (index) => {
    setTimeout(() => {
      setConversation((prevConversation) =>
        prevConversation.map((item, i) =>
          i === index ? { ...item, showThumbs: false } : item
        )
      );
    }, 3500);
  };

  const onBackButtonClick = () => {
    setShowData(false);
    setShowQuestion(false);
    setShowProgressBar(false);
    setError(null);
    setFileError(null);
    setData({});
    setConversationFeedbackAdded(false);
    setShowBackButton(false);
    setBackBtnHovered(false);
    setDisplayedFaq(null);
    setSessionId(null);
    setConversation([]);
    setConversationId(null);
    setResetFeedback(false);
    setFeedbackDisabled(false);
    // if (faqAvailable && exampleQuestions.length === 0) {
    // 	setShowFaq(true);
    // }
    setUserInput({
      ...userInput,
      problem: "",
    });
  };

  const handleClickQuestion = (question) => {
    setUserInput({
      ...userInput,
      problem: question.split("+").join(" "),
    });
  };

  return (
    <div className={"customer-container customer-container__demo"}>
      <div className="navbar">
        <WebsiteSteps isStepOne={!instanceChecked} />
        {/* <div className="navbar-logo">
          <div className="nav-left">
            <img
              src={logo || Logo}
              alt="Logo"
              onClick={resetForm}
              style={{ cursor: "pointer" }}
            />
            {showBackButton && (
              <div className="back-btn">
                <button
                  onClick={() => {
                    onBackButtonClick();
                  }}
                  onMouseEnter={() => setBackBtnHovered(true)}
                  onMouseLeave={() => setBackBtnHovered(false)}
                  style={{
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    color: backBtnHovered ? brandColor : "#6982A8",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span>+ {uiText?.askNewQuestion}</span>
                </button>
              </div>
            )}
          </div>
        </div> */}
      </div>
      <div className={`customer-wrapper ${hideForm ? "with-answer" : ""}`}>
        {showBackButton && (
          <div className="back-btn demo-button">
            <button
              onClick={() => {
                onBackButtonClick();
              }}
              onMouseEnter={() => setBackBtnHovered(true)}
              onMouseLeave={() => setBackBtnHovered(false)}
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
                color: backBtnHovered ? brandColor : "#6982A8",
                whiteSpace: "nowrap",
              }}
            >
              <span>+ {uiText?.askNewQuestion}</span>
            </button>
          </div>
        )}
        <div className="customer conversational">
          <div className={`form-page center`}>
            {!hideForm && (
              <div className={`avatar-container ${hideForm ? "hide" : ""} `}>
                {/* <div className="avatar-logo">
									</div> */}
                <div className="avatar-text">
                  {error ||
                  showProgressBar ||
                  (showData && Object.keys(data).length > 0) ||
                  displayedFaq !== null ? null : (
                    <div className="avatar-info">{uiText.form.greetings}</div>
                  )}
                </div>
              </div>
            )}
            {/* {faqAvailable && (
							<div className={faqContainerClass}>
								<Faqs
									faqs={variables.faq}
									borderColor={variables.mainColor}
									displayedFaq={displayedFaq}
									handleFaqClick={handleFaqClick}
								/>
							</div>
						)} */}
            <div className={`form-container ${hideForm ? "hide" : ""}`}>
              <form
                onSubmit={handleSubmit}
                className={`form ${hideForm ? "hide" : ""}`}
                style={{
                  height: "inherit",
                }}
              >
                <div
                  className={"form-group form-group--full"}
                  style={{
                    marginTop: "0",
                    justifyContent: "flex-end",
                    flexGrow: 0,
                    height: "auto",
                  }}
                >
                  <div className="input-container flex">
                    <AutoResizeTextarea
                      // rows={8}
                      placeholder={
                        recording || isSpinnerVisible
                          ? ""
                          : transcription === uiText?.form?.noTranscript
                          ? uiText?.form?.noTranscriptMessage
                          : uiText?.form?.questionPlaceholder
                      }
                      value={userInput.problem || ""}
                      onChange={(e) =>
                        setUserInput({ ...userInput, problem: e.target.value })
                      }
                      color={brandColor}
                      isWebsite={true}
                    />
                    {!recording && (
                      <div className="right-side">
                        <div
                          className={
                            isSpinnerVisible
                              ? "microphone disabled"
                              : "microphone disabled"
                          }
                          // onClick={isSpinnerVisible ? null : startRecording}
                          onClick={null}
                          style={{ backgroundColor: "white" }}
                        >
                          <MicrophoneIcon color="#B3CADD" />
                        </div>
                        <button
                          type="submit"
                          className={
                            userInput.device === "" || userInput.problem === ""
                              ? "submit-btn disabled"
                              : "submit-btn"
                          }
                          disabled={
                            userInput.device === "" || userInput.problem === ""
                          }
                          style={{
                            backgroundColor: brandColor,
                            color:
                              getBrightness(brandColor) > 155
                                ? "black"
                                : "white",
                            border: `1px solid ${brandColor}`,
                            width: "32px",
                            height: "32px",
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowRightIcon
                            color={
                              getBrightness(brandColor) > 155
                                ? "#000000"
                                : "#ffffff"
                            }
                          />
                        </button>
                      </div>
                    )}
                  </div>
                  {isSpinnerVisible && (
                    <div
                      style={{
                        position: "relative",
                        marginInline: "auto",
                        top: "16px",
                      }}
                    >
                      <div className="spnr"></div>
                    </div>
                  )}
                  {recording && (
                    <div style={{ position: "relative", marginInline: "auto" }}>
                      <VoiceAnimation />
                      <div className="stop-recording__container">
                        <div
                          className="stop-recording__background"
                          style={{ backgroundColor: brandColor }}
                        ></div>
                        <div
                          className="stop-recording"
                          onClick={stopRecording}
                          style={{ backgroundColor: brandColor }}
                        >
                          <span
                            style={{
                              borderColor:
                                getBrightness(brandColor) > 155
                                  ? "#000000"
                                  : "#ffffff",
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {!showFaq ? (
										<div className={exampleQuestionsClass}>
											{exampleQuestions.length > 0 ? (
												<div
													className="selection-title"
													style={{ textAlign: "left", paddingTop: "20px" }}
												>
													{uiText?.form?.exampleQuestions}:
												</div>
											) : null}
											<ExampleQuestions
												exampleQuestions={exampleQuestions}
												borderColor={variables.mainColor}
												cursor={
													isSpinnerVisible || recording
														? "not-allowed"
														: "pointer"
												}
												onClick={
													isSpinnerVisible || recording || loading
														? null
														: handleClickQuestion
												}
											/>
										</div>
									) : null} */}
                  <OtherQuestions
                    exampleQuestions={questions}
                    borderColor={brandColor}
                    cursor={
                      isSpinnerVisible || recording ? "not-allowed" : "pointer"
                    }
                    onClick={
                      isSpinnerVisible || recording || loading
                        ? null
                        : handleClickQuestion
                    }
                    isDemo={true}
                  />
                </div>
              </form>
            </div>
            <div
              className={
                showProgressBar ||
                error ||
                fileError ||
                (showData && Object.keys(data).length > 0) ||
                displayedFaq !== null ||
                conversation.length > 0
                  ? "feedback-container show"
                  : "feedback-container"
              }
              id="results"
            >
              {(showProgressBar || conversation.length > 0) && (
                <div className="customer-question">
                  <div className="customer-question__top">{uiText?.you}:</div>
                  <div className="customer-question__middle">
                    {firstQuestion}
                  </div>
                  <div className="customer-question__bottom">
                    {formatDeviceName(userInput.device)}
                  </div>
                </div>
              )}
              <div
                className={"feedback card hide"}
                style={{ position: "relative" }}
              >
                {/* {faqAvailable && displayedFaq !== null && (
									<div className="faq-answer__big">
										<Faqs
											faqs={variables.faq}
											borderColor={variables.mainColor}
											displayedFaq={displayedFaq}
											handleFaqClick={handleFaqClick}
										/>
									</div>
								)} */}
                {error && !conversation?.length && (
                  <NoResults txt="" protectionSystem={false} />
                )}
                {/* {fileError && (
									<Alert
										message={uiText?.alerts?.fileOpenError}
										duration={5000} // Duration in milliseconds
										close={() => setFileError(null)}
									/>
								)} */}
                {showProgressBar && !conversation.length && (
                  <div
                    className="progress-desktop"
                    style={{
                      maxWidth: "400px",
                      margin: "0 auto",
                      width: "100%",
                    }}
                  >
                    <ProgressBar
                      progress={progress}
                      status={status}
                      color={NoResults}
                      showBar={false}
                    />
                  </div>
                )}

                {showData && conversation.length > 0 ? (
                  <>
                    {conversation.map((item, index) => {
                      const {
                        data,
                        question,
                        recordId,
                        showThumbs,
                        device,
                        conversationError,
                      } = item;

                      return (
                        <div
                          key={index}
                          ref={
                            index === conversation.length - 1
                              ? latestConversationRef
                              : null
                          }
                        >
                          {index !== 0 ? (
                            <div
                              className="customer-question"
                              style={{ marginTop: "16px" }}
                            >
                              <div className="customer-question__top">
                                {uiText?.you}:
                              </div>
                              <div className="customer-question__middle">
                                {question}
                              </div>
                              <div className="customer-question__bottom">
                                {formatDeviceName(device)}
                              </div>
                            </div>
                          ) : null}
                          <div className="feedback-instructions">
                            {data?.model_response.length > 0 &&
                            !data?.empty_answer ? (
                              <>
                                <div className="feedback-documents__col">
                                  <div className="assistant-avatar">
                                    <SAicon
                                      circleColor={brandColor}
                                      color={
                                        getBrightness(brandColor) > 155
                                          ? "black"
                                          : "white"
                                      }
                                    />
                                    <span>Service Assistant AI</span>
                                  </div>
                                  {typeof data.warning === "string" &&
                                  data.warning.length > 0 ? (
                                    <Warning warningText={data.warning} />
                                  ) : null}
                                  <div className="feedback-instruction">
                                    <StepsComponent
                                      inputText={data?.model_response}
                                      language={userInput.language}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : !error ? (
                              <NoResults
                                txt=""
                                protectionSystem={
                                  userInput.instance_type === "customer"
                                }
                              />
                            ) : null}
                            <div
                              className={
                                data?.exact_file
                                  ? "feedback-documents"
                                  : "feedback-documents none"
                              }
                              style={{ padding: "0px" }}
                            >
                              {!data?.empty_answer
                                ? (() => {
                                    return (
                                      <div
                                        className="feedback-documents__col border-top"
                                        style={{
                                          padding: "1rem 0 0",
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <div className="feedback-file conversational__feedback-file">
                                          <div className="file-container">
                                            <div className="file-container__header">
                                              Source:
                                            </div>
                                            <div className="customer-question__bottom">
                                              {formatDeviceName(
                                                userInput.device
                                              )}
                                            </div>
                                          </div>
                                          <UserFeedback
                                            recordId={recordId}
                                            data={data}
                                            question={question}
                                            index={index}
                                            showingThumbs={showThumbs}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })()
                                : null}
                            </div>
                          </div>
                          {data &&
                            Object.keys(data).length > 0 &&
                            data?.exact_file === null &&
                            data?.empty_answer &&
                            !conversationError(
                              <NoResults
                                txt=""
                                protectionSystem={
                                  userInput.instance_type === "customer"
                                }
                              />
                            )}
                          {conversationError && (
                            <NoResults txt="" protectionSystem={false} />
                          )}
                        </div>
                      );
                    })}

                    {showProgressBar && (
                      <div className="customer-question">
                        <div className="customer-question__top">
                          {uiText?.you}:
                        </div>
                        <div className="customer-question__middle">
                          {userInput.problem}
                        </div>
                        <div className="customer-question__bottom">
                          {formatDeviceName(userInput.device)}
                        </div>
                      </div>
                    )}
                    {showProgressBar && (
                      <div
                        className="progress-desktop"
                        style={{
                          maxWidth: "400px",
                          margin: "0 auto",
                          width: "100%",
                        }}
                      >
                        <ProgressBar
                          progress={progress}
                          status={status}
                          color={brandColor}
                          showBar={false}
                        />
                      </div>
                    )}
                    {showData &&
                      conversation.length > 0 &&
                      conversation.some(checkIfFeedbackIsNeeded) && (
                        <ConversationFeedback
                          backgroundColor={brandColor}
                          // color={
                          // 	getBrightness(variables.mainColor) > 155
                          // 		? "black"
                          // 		: "white"
                          // }
                          borderColor={brandColor}
                          onClick={onConversationFeedbackSubmit}
                          conversationId={conversationId}
                          resetFeedback={resetFeedback}
                          loading={loading}
                          onBackButtonClick={onBackButtonClick}
                          isDemo={true}
                        />
                      )}
                    <div
                      className="input-container flex"
                      style={{
                        position: "sticky",
                        bottom: "1rem",
                        marginTop: "auto",
                        opacity: feedbackDisabled ? 0 : 1,
                        transition: "opacity 0.5s",
                      }}
                    >
                      <AutoResizeTextarea
                        // rows={8}
                        placeholder={
                          recording || isSpinnerVisible
                            ? ""
                            : transcription === uiText?.form?.noTranscript
                            ? uiText?.form?.noTranscriptMessage
                            : uiText?.form?.followUpPlaceholder
                        }
                        value={userInput.problem || ""}
                        onChange={(e) =>
                          setUserInput({
                            ...userInput,
                            problem: e.target.value,
                          })
                        }
                        color={brandColor}
                        idForArea={"follow-up"}
                        isDisabled={feedbackDisabled}
                        isWebsite={true}
                      />
                      {!recording && (
                        <div className="right-side">
                          <div
                            className={
                              isSpinnerVisible || feedbackDisabled
                                ? "microphone disabled"
                                : "microphone disabled"
                            }
                            // onClick={
                            // 	isSpinnerVisible || feedbackDisabled
                            // 		? null
                            // 		: startRecording
                            // }
                            onClick={null}
                            style={{ backgroundColor: "white" }}
                          >
                            <MicrophoneIcon color="#B3CADD" />
                          </div>
                          <button
                            type="submit"
                            onClick={() => {
                              handleSubmit();
                            }}
                            className={
                              userInput.device === "" ||
                              userInput.problem === ""
                                ? "submit-btn disabled"
                                : "submit-btn"
                            }
                            disabled={
                              userInput.device === "" ||
                              userInput.problem === "" ||
                              feedbackDisabled
                            }
                            style={{
                              backgroundColor: brandColor,
                              color:
                                getBrightness(brandColor) > 155
                                  ? "black"
                                  : "white",
                              border: `1px solid ${brandColor}`,
                              width: "32px",
                              height: "32px",
                              padding: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ArrowRightIcon
                              color={
                                getBrightness(brandColor) > 155
                                  ? "#000000"
                                  : "#ffffff"
                              }
                            />
                          </button>
                        </div>
                      )}
                    </div>
                    {isSpinnerVisible && (
                      <div
                        style={{
                          position: "relative",
                          marginInline: "auto",
                          top: "16px",
                        }}
                      >
                        <div className="spnr"></div>
                      </div>
                    )}
                    {recording && (
                      <div
                        style={{ position: "relative", marginInline: "auto" }}
                      >
                        <VoiceAnimation />
                        <div className="stop-recording__container">
                          <div
                            className="stop-recording__background"
                            style={{ backgroundColor: brandColor }}
                          ></div>
                          <div
                            className="stop-recording"
                            onClick={stopRecording}
                            style={{ backgroundColor: brandColor }}
                          >
                            <span
                              style={{
                                borderColor:
                                  getBrightness(brandColor) > 155
                                    ? "#000000"
                                    : "#ffffff",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoWebsite;
