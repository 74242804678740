import { ReactComponent as LockIcon } from "../../assets/lock.svg";

 const openCalendlyLink = () => {
		window.open(
			"https://calendly.com/innential/kris-innential-service-assistant-ai",
			"_blank"
		);
 };

const FullAccessWelcome = () => {
	return (
		<div className="access-card">
			<LockIcon/>
			<div className="access-card__title">Get Full Access</div>
			<div className="access-card__description">
				You have reached your response limit. To unlock your limit please
				contact us.
			</div>
			<button className="access-card__button" onClick={openCalendlyLink}>Book a Call</button>
		</div>
	);
};

export default FullAccessWelcome;
