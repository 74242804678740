import bulgarian from './langs/bulgarian.json';
import chinese from './langs/chinese.json';
import czech from './langs/czech.json';
import danish from './langs/danish.json';
import dutch from './langs/dutch.json';
import english from './langs/english.json';
import estonian from './langs/estonian.json';
import finnish from './langs/finnish.json';
import french from './langs/french.json';
import german from './langs/german.json';
import greek from './langs/greek.json';
import hungarian from './langs/hungarian.json';
import indonesian from './langs/indonesian.json';
import italian from './langs/italian.json';
import japanese from './langs/japanese.json';
import korean from './langs/korean.json';
import latvian from './langs/latvian.json';
import lithuanian from './langs/lithuanian.json';
import norwegian from './langs/norwegian.json';
import polish from './langs/polish.json';
import portuguese from './langs/portuguese.json';
import portugueseBrazilian from './langs/portuguese-brazilian.json';
import romanian from './langs/romanian.json';
import russian from './langs/russian.json';
import slovak from './langs/slovak.json';
import slovenian from './langs/slovenian.json';
import spanish from './langs/spanish.json';
import swedish from './langs/swedish.json';
import turkish from './langs/turkish.json';
import ukrainian from './langs/ukrainian.json';


const translations = {
  'Bulgarian': bulgarian,
  'Chinese': chinese,
  'Czech': czech,
  'Danish': danish,
  'Dutch': dutch,
  'English': english,
  'Estonian': estonian,
  'Finnish': finnish,
  'French': french,
  'German': german,
  'Greek': greek,
  'Hungarian': hungarian,
  'Italian': italian,
  'Indonesian': indonesian,
  'Japanese': japanese,
  'Korean': korean,
  'Latvian': latvian,
  'Lithuanian': lithuanian,
  'Norwegian': norwegian,
  'Polish': polish,
  'Portuguese': portuguese,
  'portuguese-brazilian': portugueseBrazilian,
  'Romanian': romanian,
  'Russian': russian,
  'Slovak': slovak,
  'Slovenian': slovenian,
  'Spanish': spanish,
  'Swedish': swedish,
  'Turkish': turkish,
  'Ukrainian': ukrainian
}

export default translations;