import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as SlidersIcon } from "../assets/sliders.svg";
import { useUIText } from "../hooks";

const SwitchCustomer = ({
	view,
	handleViewChange,
	optionsOpen,
	setOptionsOpen,
	children,
	showSwitch,
	mainColor,
	secondChildren,
}) => {
	const uiText = useUIText();
	const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1111);

	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth < 1111);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div style={{ position: "relative", marginLeft: "auto" }}>
			{isMobileView ? (
				optionsOpen ? (
					<div className="switch-container visible">
						<button
							className="close-button"
							onClick={() => setOptionsOpen(false)}
							style={{ marginLeft: "auto" }}
						>
							<CloseIcon />
						</button>
						{showSwitch ? (
							<div className="customer-switch">
								<p
									className={
										view === "Installer"
											? "installer-text checked"
											: "installer-text"
									}
									style={{
										color: view === "Installer" ? mainColor : "#90A0B7",
									}}
								>
									{uiText?.instanceType?.installer}
								</p>
								<label className="switch">
									<input
										type="checkbox"
										id="modeToggle"
										onChange={handleViewChange}
										checked={view === "Customer"}
									/>
									<span
										className="slider"
										style={{ backgroundColor: mainColor }}
									></span>
								</label>
								<p
									className={
										view === "Installer"
											? "customer-text"
											: "customer-text checked"
									}
									style={{
										color: view === "Installer" ? "#90A0B7" : mainColor,
									}}
								>
									{uiText?.instanceType?.customer}
								</p>
							</div>
						) : null}

						<div style={{ paddingTop: 12, paddingBottom: 12 }}>{children}</div>
						{secondChildren ? (
							<div
								style={{
									paddingTop: 16,
									paddingBottom: 16,
									borderTop: "1px solid rgba(144, 160, 183, 0.12)",
									width: "100%",
								}}
							>
								{secondChildren}
							</div>
						) : null}
					</div>
				) : (
					<button
						className="option-button"
						onClick={() => {
							setOptionsOpen(true);
						}}
					>
						<SlidersIcon />
						{/* <div className="option-dots">
							<span></span>
							<span></span>
							<span></span>
						</div> */}
					</button>
				)
			) : (
				<div style={{ display: "flex" }}>
					{children}
					{secondChildren}
					{showSwitch ? (
						<div className="switch-container">
							<div className="customer-switch main">
								<p
									className={
										view === "Installer"
											? "installer-text checked"
											: "installer-text"
									}
									style={{
										color: view === "Installer" ? mainColor : "#90A0B7",
									}}
								>
									{uiText?.instanceType?.installer}
								</p>
								<label className="switch">
									<input
										type="checkbox"
										id="modeToggle"
										onChange={handleViewChange}
										checked={view === "Customer"}
									/>
									<span
										className="slider"
										style={{ backgroundColor: mainColor }}
									></span>
								</label>
								<p
									className={
										view === "Installer"
											? "customer-text"
											: "customer-text checked"
									}
									style={{
										color: view === "Installer" ? "#90A0B7" : mainColor,
									}}
								>
									{uiText?.instanceType?.customer}
								</p>
							</div>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default SwitchCustomer;
