function DownloadIcon({ color }) {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_131_2014)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.07741 13.5774C6.40284 13.252 6.93048 13.252 7.25592 13.5774L10 16.3215L12.7441 13.5774C13.0695 13.252 13.5971 13.252 13.9226 13.5774C14.248 13.9028 14.248 14.4305 13.9226 14.7559L10.5893 18.0893C10.2638 18.4147 9.73618 18.4147 9.41074 18.0893L6.07741 14.7559C5.75197 14.4305 5.75197 13.9028 6.07741 13.5774Z"
					fill={color || "#5A55AB"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10 9.16667C10.4602 9.16667 10.8333 9.53977 10.8333 10V17.5C10.8333 17.9602 10.4602 18.3333 10 18.3333C9.53977 18.3333 9.16667 17.9602 9.16667 17.5V10C9.16667 9.53977 9.53977 9.16667 10 9.16667Z"
					fill={color || "#5A55AB"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.22298 1.6618C8.35672 1.62103 9.48493 1.83798 10.5227 2.29633C11.5604 2.75468 12.4807 3.44248 13.2142 4.30795C13.8062 5.00658 14.2642 5.80629 14.567 6.66666L14.9993 6.66666C16.0619 6.66597 17.0978 7.00382 17.9554 7.63122C18.8132 8.25875 19.4487 9.14329 19.7697 10.1565C20.0907 11.1697 20.0806 12.2588 19.7406 13.2658C19.4007 14.2728 18.7487 15.1453 17.8793 15.7567C17.5029 16.0214 16.9831 15.9308 16.7183 15.5543C16.4536 15.1779 16.5442 14.6581 16.9206 14.3933C17.5002 13.9858 17.9349 13.4041 18.1615 12.7328C18.3881 12.0614 18.3949 11.3353 18.1809 10.6599C17.9669 9.98442 17.5432 9.39472 16.9714 8.97637C16.3995 8.55802 15.7092 8.33277 15.0006 8.33333H13.95C13.5695 8.33333 13.2374 8.07564 13.1428 7.70711C12.9235 6.85244 12.5132 6.05865 11.9427 5.38551C11.3722 4.71236 10.6565 4.17741 9.84932 3.82091C9.04217 3.46442 8.16468 3.29568 7.28288 3.32739C6.40109 3.3591 5.53798 3.59044 4.75853 4.00399C3.97908 4.41754 3.3036 5.00252 2.78295 5.71491C2.2623 6.42729 1.91004 7.2485 1.75269 8.11672C1.59534 8.98494 1.63701 9.87755 1.87455 10.7273C2.11209 11.5771 2.53931 12.362 3.12406 13.0227C3.42906 13.3674 3.39691 13.8941 3.05225 14.1991C2.70759 14.5041 2.18093 14.4719 1.87593 14.1273C1.12411 13.2777 0.574819 12.2686 0.26941 11.176C-0.035999 10.0834 -0.0895679 8.9358 0.112736 7.81952C0.31504 6.70323 0.767945 5.64738 1.43735 4.73147C2.10676 3.81555 2.97523 3.06342 3.97738 2.53171C4.97953 2 6.08925 1.70257 7.22298 1.6618Z"
					fill={color || "#5A55AB"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_131_2014">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default DownloadIcon;
