import React, { useEffect, useRef, useState } from "react";
import { renderIcon } from "../utils";

const OtherQuestionsCard = React.forwardRef(
	({ question, borderColor, onClick, cursor, isDemo }, ref) => {
		const [cardHovered, setCardHovered] = useState(false);
		return (
			<div
				className="example-question"
				onClick={() => onClick(isDemo ? question : question.question)}
				style={{
					borderColor: isDemo? null : borderColor,
					color: cardHovered ? borderColor : "#152540",
					cursor: cursor,
					minHeight: "32px",
					boxShadow: cardHovered
						? `0px 0px 0px 1px ${borderColor} inset`
						: "none",
				}}
				onMouseEnter={() => setCardHovered(true)}
				onMouseLeave={() => setCardHovered(false)}
				ref={ref} // Pass the ref here
			>
				{isDemo? null : (<span>{renderIcon(question.icon, null, 2)}</span>)}
				{isDemo ? question.split('+').join(" ") : question.question}
			</div>
		);
	}
);

const OtherQuestions = ({ exampleQuestions, onClick, borderColor, cursor, isDemo=false }) => {
	const questionRefs = useRef([]);

	useEffect(() => {
		if (questionRefs.current.length) {
			// Reset all heights before recalculating
			questionRefs.current.forEach((ref) => {
				if (ref) {
					ref.style.height = "auto";
				}
			});

			let maxHeight = 0;

			// Find the tallest question
			questionRefs.current.forEach((ref) => {
				if (ref) {
					maxHeight = Math.max(maxHeight, ref.clientHeight);
				}
			});

			// Set all questions to the height of the tallest question
			questionRefs.current.forEach((ref) => {
				if (ref) {
					ref.style.height = `${maxHeight}px`;
				}
			});
		}
	}, [exampleQuestions]);

	return (
		<div className="example-questions other-questions">
			{(exampleQuestions).map((question, index) => (
				<OtherQuestionsCard
					key={index + 1}
					question={question}
					borderColor={borderColor}
					onClick={onClick}
					cursor={cursor}
					ref={(el) => (questionRefs.current[index] = el)}
					isDemo={isDemo}
				/>
			))}
		</div>
	);
};

export default OtherQuestions;
