function ResultsIcon({ color, circleColor }) {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill={circleColor || "#8745EF"} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6666 11C11.6666 10.6318 11.9651 10.3333 12.3333 10.3333H21C21.3681 10.3333 21.6666 10.6318 21.6666 11C21.6666 11.3682 21.3681 11.6667 21 11.6667H12.3333C11.9651 11.6667 11.6666 11.3682 11.6666 11Z"
				fill={color || "white"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6666 15C11.6666 14.6318 11.9651 14.3333 12.3333 14.3333H21C21.3681 14.3333 21.6666 14.6318 21.6666 15C21.6666 15.3682 21.3681 15.6667 21 15.6667H12.3333C11.9651 15.6667 11.6666 15.3682 11.6666 15Z"
				fill={color || "white"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6666 19C11.6666 18.6318 11.9651 18.3333 12.3333 18.3333H21C21.3681 18.3333 21.6666 18.6318 21.6666 19C21.6666 19.3682 21.3681 19.6667 21 19.6667H12.3333C11.9651 19.6667 11.6666 19.3682 11.6666 19Z"
				fill={color || "white"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.33337 11C8.33337 10.6318 8.63185 10.3333 9.00004 10.3333H9.00671C9.3749 10.3333 9.67337 10.6318 9.67337 11C9.67337 11.3682 9.3749 11.6667 9.00671 11.6667H9.00004C8.63185 11.6667 8.33337 11.3682 8.33337 11Z"
				fill={color || "white"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.33337 15C8.33337 14.6318 8.63185 14.3333 9.00004 14.3333H9.00671C9.3749 14.3333 9.67337 14.6318 9.67337 15C9.67337 15.3682 9.3749 15.6667 9.00671 15.6667H9.00004C8.63185 15.6667 8.33337 15.3682 8.33337 15Z"
				fill={color || "white"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.33337 19C8.33337 18.6318 8.63185 18.3333 9.00004 18.3333H9.00671C9.3749 18.3333 9.67337 18.6318 9.67337 19C9.67337 19.3682 9.3749 19.6667 9.00671 19.6667H9.00004C8.63185 19.6667 8.33337 19.3682 8.33337 19Z"
				fill={color || "white"}
			/>
		</svg>
	);
}

export default ResultsIcon;
