function ThumbUpCustomer({ color }) {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="thumbs-up">
				<path
					id="Vector (Stroke)"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.05743 1.39594C7.16443 1.15519 7.40318 1.00003 7.66664 1.00003C8.37388 1.00003 9.05216 1.28098 9.55225 1.78108C10.0524 2.28118 10.3333 2.95945 10.3333 3.6667V5.6667H13.4366C13.7251 5.66403 14.0107 5.72381 14.274 5.84196C14.5385 5.96067 14.774 6.13547 14.9643 6.35425C15.1545 6.57303 15.2949 6.83056 15.3758 7.10899C15.4566 7.38742 15.4759 7.6801 15.4324 7.96674L14.5124 13.9667C14.5124 13.9668 14.5125 13.9665 14.5124 13.9667C14.44 14.4434 14.1977 14.8784 13.8303 15.1907C13.4638 15.5022 12.9974 15.6712 12.5165 15.6667H2.99997C2.46954 15.6667 1.96083 15.456 1.58576 15.0809C1.21068 14.7058 0.999969 14.1971 0.999969 13.6667V9.00003C0.999969 8.4696 1.21068 7.96089 1.58576 7.58582C1.96083 7.21074 2.46954 7.00003 2.99997 7.00003H4.56672L7.05743 1.39594ZM5.66664 7.80817L8.07186 2.39643C8.27278 2.46053 8.45755 2.57199 8.60945 2.72389C8.85949 2.97394 8.99997 3.31307 8.99997 3.6667V6.33336C8.99997 6.70155 9.29845 7.00003 9.66664 7.00003H13.44L13.4475 6.99999C13.5442 6.99889 13.6399 7.01882 13.728 7.05839C13.8162 7.09796 13.8947 7.15623 13.9581 7.22916C14.0216 7.30209 14.0684 7.38793 14.0953 7.48074C14.1222 7.57346 14.1287 7.67093 14.1142 7.76639C14.1142 7.76648 14.1142 7.7663 14.1142 7.76639L13.1942 13.7667C13.1701 13.9257 13.0893 14.0706 12.9668 14.1747C12.8443 14.2789 12.6883 14.3352 12.5275 14.3334L5.66664 14.3334V7.80817ZM4.3333 14.3334V8.33336H2.99997C2.82316 8.33336 2.65359 8.4036 2.52856 8.52863C2.40354 8.65365 2.3333 8.82322 2.3333 9.00003V13.6667C2.3333 13.8435 2.40354 14.0131 2.52856 14.1381C2.65359 14.2631 2.82316 14.3334 2.99997 14.3334H4.3333Z"
					fill={color || "#8745EF"}
				/>
			</g>
		</svg>
	);
}

export default ThumbUpCustomer;
