import React from "react";

const LoadingAnimation = ({ color = "#8745EF" }) => {
	return (
		<div className="svg-container">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				<g id="node_1_0">
					<rect
						width="24"
						height="24"
						fill="white"
						id="node_1_0_0"
						x="0"
						y="0"
						transform="rotate(0 12 12)"
					/>
					<g id="loadingtype_1">
						<circle
							id="Ellipse_35_2"
							cx="4"
							cy="12"
							r="2"
							fill="#8494B2"
							style={{
								animation: `pulse 1.5s ease-in-out infinite`,
								animationDelay: "0s",
							}}
						/>
						<circle
							id="Ellipse_36_3"
							cx="12"
							cy="12"
							r="2"
							fill="#8494B2"
							style={{
								animation: `pulse 1.5s ease-in-out infinite`,
								animationDelay: "0.5s",
							}}
						/>
						<circle
							id="Ellipse_37_4"
							cx="20"
							cy="12"
							r="2"
							fill="#8494B2"
							style={{
								animation: `pulse 1.5s ease-in-out infinite`,
								animationDelay: "1s",
							}}
						/>
					</g>
				</g>
			</svg>
			<style>
				{`
          @keyframes pulse {
            0%, 100% {
              r: 2px;
              fill: #8494B2;
            }
            50% {
              r: 3px;
              fill: ${color};
            }
          }
        `}
			</style>
		</div>
	);
};

export default LoadingAnimation;
