import { Tooltip } from "react-tooltip";
import CheckedIcon from "../assets/checked";

const brandColor = "#8745EF";
const secondaryColor = "#90A0B7";
const currentColor = "rgb(28, 181, 92)";

const steps = [
	{
		text: "Scan Website",
	},
	{
		text: "Test Your AI",
	},
	{
		text: "Add More Data ",
	},
	{
		text: "Embed To  Website ",
	},
];
const WebsiteSteps = ({ isStepOne }) => {
	return (
		<div
			style={{
				// borderBottom: "1px solid #d2dfeb",
				display: "flex",
				alignItems: "center",
				// background: "#F5FAFF",
			}}
		>
			<div className="web-wrapper">
				<div className="web-steps">
					{steps.map((step, index) => (
						<div className="web-step" key={index}>
							<div
								className="web-step__circle"
								style={{
									background:
										index === 0 || (index === 1 && !isStepOne)
											? brandColor
											: isStepOne && index === 1
											? currentColor
											: secondaryColor,
								}}
								id={"step-tooltip" + index}
								data-tooltip-id={"stepTooltip" + index}
							>
								{index === 0 || (index === 1 && !isStepOne) ? (
									<CheckedIcon />
								) : (
									index + 1
								)}
								<Tooltip
									id={"stepTooltip" + index}
									className="step-tooltip"
									place="bottom"
									effect="solid"
									positionStrategy="fixed"
									// afterShow={adjustTooltipPosition}
								>
									<div>{step.text}</div>
								</Tooltip>
							</div>
							<div
								className={
									index <= 1 ? "web-step__text bolder" : "web-step__text"
								}
							>
								{step.text}
							</div>
							{index <= 2 ? (
								<div className="connector">
									<span></span>
								</div>
							) : null}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default WebsiteSteps;
